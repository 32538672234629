var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              {
                staticClass: "alert alert-light alert-elevate fade show",
                attrs: { role: "alert" },
              },
              [
                _c("div", { staticClass: "alert-icon" }, [
                  _c("i", {
                    class: {
                      "la la-check kt-font-success": _vm.googleEmail,
                      "la la-times kt-font-danger": !_vm.googleEmail,
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "alert-text",
                    class: {
                      "kt-font-bold": !_vm.googleEmail,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.statusMessage) + " ")]
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet" }, [
          _c(
            "div",
            { staticClass: "kt-portlet__body" },
            [_c("GoogleClassroomConnect")],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }