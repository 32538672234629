<template>
<div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col">
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i
                            :class="{
                                'la la-check kt-font-success': googleEmail,
                                'la la-times kt-font-danger': !googleEmail,
                            }"
                        />
                    </div>
                    <div
                        class="alert-text"
                        :class="{
                            'kt-font-bold': !googleEmail
                        }"
                    >
                        {{ statusMessage }}
                    </div>
                </div>
            </div>
        </div>

        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <GoogleClassroomConnect />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import GoogleClassroomConnect from '../../components/GoogleClassroomConnect.vue';
import userMixins from '../../store/mixins/userMixins';
import Types from '../../store/Types';

export default {
    name: 'GoogleAccount',
    components: {
        GoogleClassroomConnect
    },
    mixins: [userMixins],
    data() {
        return {
            saving: false,
            linkError: false,
            showDisconnectNotice: false,
            host: window.location.href.indexOf('localhost') > -1 ? 'http://localhost:3000' : '',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
            googleSetting: (state) => state.user.school.googleSetting,
            googleFriendlyName: (state) => `${state.user.firstName} ${state.user.lastName}`,
            googleEmail: (state) => state.user.googleEmail,
            unlinkedGoogleEmail: (state) => state.user.unlinkedGoogleEmail,
            googleDomain(state) {
                return state.user.googleEmail ? state.user.googleEmail.split('@')[1] : '';
            },
            googleUser(state) {
                return state.user.googleEmail ? state.user.googleEmail.split('@')[0] : '';
            },
        }),
        statusMessage() {
            if (this.googleEmail) {
                return "This step is completed";
            } else {
                return "Please link your google email to continue";
            }
        },
        isSetupWizard() {
            return this.$route.name == 'SetupWizard';
        },
        redirectUri() {
            const { pathname } = window.location;
            return `/auth/google/redirect?callbackUrl=${pathname}`;
        },
    },
    mounted() {
        const v = this;
        const urlParams = new URLSearchParams(window.location.search);
        const search = Object.fromEntries(urlParams);

        if (Object.keys(search).includes('error')) {
            const errorValue = search.error;
            if (errorValue === 'googleEmailMismatch') {
                v.linkError = true;
            }
        }

    },
    methods: {
        redirect() {
            window.location.href = '/auth/google/redirect';
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/pages/pricing/pricing-1.scss";

.kt-pricing-1__price {
    font-size: 2rem !important;
    font-weight: 500 !important;
    margin-bottom: 0.571rem !important;
    margin-top: 140px !important;
    color: kt-base-color(label, 3) !important;
}
</style>